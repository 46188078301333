







































































































.businessCooperation{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner3.png");
}
.main{
  position: relative;
  width: 1440px;
  margin: 0 auto;
  h3{
    position: relative;
    text-align: center;
    padding: 40px 0 40px;
    color: #333;
    font-size: 30px;
    font-weight: bold;
  }
}
.main1{
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #FFF;
  }
}
.ccFirstRow{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
  .ccInner{
    flex: 1;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    transition: 0.5s;
    padding: 0 40px 34px;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    }
    img{
      width: 100px!important;
      height: 100px!important;
      margin-top: -50px;
      margin-bottom: 20px;
    }
    h4{
      text-align: center;
      font-size: 24px;
      color: #333;
      margin-bottom: 7px;
    }
    p{
      text-align: center;
      font-size: 16px;
      line-height: 1.4;
      color: #666;
    }
  }
}
.cooperationAdvantages{
  display: flex;
  position: relative;
  .caInner{
    flex: 1;
    margin-right: 30px;
    height: 400px;
    margin-bottom: 60px;
    background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
    transition: 0.5s;
    border-radius: 12px;
    text-align: center;
    &:hover{
      background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    }
    &:last-child{
      margin-right: 0;
    }
    img{
      width: 180px!important;
      height: 180px!important;
      margin-bottom: 10px;
      margin-top: 48px;
    }
    h4{
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #333;
    }
    p{
      font-size: 16px;
      line-height: 1.4;
      color: #666;
    }
  }
}

@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }

  .ccFirstRow .ccInner{
    padding:0 20px 34px;
  }

}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
