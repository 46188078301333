.businessCooperation {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
}
.businessCooperation::-webkit-scrollbar {
  width: 0 !important;
}
.banner {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner3.png");
}
.main {
  position: relative;
  width: 1440px;
  margin: 0 auto;
}
.main h3 {
  position: relative;
  text-align: center;
  padding: 40px 0 40px;
  color: #333;
  font-size: 30px;
  font-weight: bold;
}
.main1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background: #FFF;
}
.ccFirstRow {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
}
.ccFirstRow .ccInner {
  flex: 1;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  transition: 0.5s;
  padding: 0 40px 34px;
}
.ccFirstRow .ccInner:last-child {
  margin-right: 0;
}
.ccFirstRow .ccInner:hover {
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
}
.ccFirstRow .ccInner img {
  width: 100px!important;
  height: 100px!important;
  margin-top: -50px;
  margin-bottom: 20px;
}
.ccFirstRow .ccInner h4 {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 7px;
}
.ccFirstRow .ccInner p {
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  color: #666;
}
.cooperationAdvantages {
  display: flex;
  position: relative;
}
.cooperationAdvantages .caInner {
  flex: 1;
  margin-right: 30px;
  height: 400px;
  margin-bottom: 60px;
  background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
  transition: 0.5s;
  border-radius: 12px;
  text-align: center;
}
.cooperationAdvantages .caInner:hover {
  background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
}
.cooperationAdvantages .caInner:last-child {
  margin-right: 0;
}
.cooperationAdvantages .caInner img {
  width: 180px!important;
  height: 180px!important;
  margin-bottom: 10px;
  margin-top: 48px;
}
.cooperationAdvantages .caInner h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}
.cooperationAdvantages .caInner p {
  font-size: 16px;
  line-height: 1.4;
  color: #666;
}
@media (max-width: 1500px) {
  .main {
    width: 1200px;
  }
  .banner {
    height: 250px;
  }
  .ccFirstRow .ccInner {
    padding: 0 20px 34px;
  }
}
.clearfix:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.clearfix {
  zoom: 1;
}
